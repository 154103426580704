import {styled as styledMUI} from "@mui/material/styles";
import {Button} from "@mui/material";
import colors from "../../config/colors";

export const ButtonFromMUI = styledMUI(Button)(({theme}) => ({
    boxShadow: `2px 4px 4px`,
    ':hover': {
        boxShadow: `1px 2px 4px`,
    }
}));
