import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {createTheme, styled as styledMUI, styled, ThemeProvider, useTheme} from "@mui/material/styles";
import links from "./config/links";
import {enumListSizeDevices} from "./config/responsive/configResponsive";
import {Container} from "@mui/material";
import Footer from "./components/footer/Footer";
import HeaderWithTitle from "./components/header/HeaderWithTitle";
import Home from "./screens/home/Home";
import {HelperResponsive} from "./helper/Helper";
import HttpError from "./screens/error/ErrorHandler";
import AnswerTest from './screens/answereTest/login/AnswerTest';
import { AlertPopup } from './components/MUIConfig/alert_popup/AlertPopup';
import { ProgressBarInRoots } from './components/MUIConfig/progressbar_in_root/ProgressBarInRoot';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectedTheme } from './store/themeModeSlice';
import { SelectedAlert } from './store/AlertStateModeSlice';
import { SelectedProgressbarInRoot } from './store/ProgressbarInRootSlice';
import { enumTypeOfAlert } from './components/MUIConfig/alert_popup/interfaceAlertPopup';
import Test from './screens/answereTest/test/Test';

const mainThem = createTheme({
    direction: "rtl"
});
const AddHeader = (element: any, TypeOfDisplay: enumListSizeDevices) => {
    return (
        <div>
            <HeaderWithTitle  name={"سامانه پاسخگویی"}/>
            {element}
            <Footer listTitleOfAccessFast={[]}/>
        </div>
    );

}

function App() {
    const themeInStore = useSelector(selectedTheme);
    const alertStateInStore = useSelector(SelectedAlert);
    const progressbarInStore = useSelector(SelectedProgressbarInRoot);
    const { t, i18n } = useTranslation();
    
    const [displayType, SetDisplayType] = useState<enumListSizeDevices>(0);
    const mTheme = useTheme();
    useEffect(() => {
        SetDisplayType(HelperResponsive());
    }, []);
    return (
        <ThemeProvider theme={mainThem}>
            <div dir={mainThem.direction} style={{direction: mainThem.direction}}>
            <AlertPopup typeOfWarning={alertStateInStore.alerts.typeAlert ? Object.values(enumTypeOfAlert)[Object.values(enumTypeOfAlert).indexOf(alertStateInStore.alerts.typeAlert as unknown as enumTypeOfAlert)] : enumTypeOfAlert.error}
                    text={alertStateInStore.alerts.text} lastTimeecondsUpdate={alertStateInStore.alerts.lastTimeSecondsUpdate} />
                <ProgressBarInRoots isVisible={progressbarInStore.isVisibility} value={progressbarInStore.value} lastTimeMilisUpdate={progressbarInStore.lastTimeMilisUpdate} />
                <Router>
                    <Routes>
                        <Route path={links.home} element={AddHeader(<Home typeOfDisplay={displayType}/>, displayType)}/>

                        <Route path={links.answerTest.login} element={AddHeader(<AnswerTest typeOfDisplay={displayType}/>, displayType)}/>
                        <Route path={links.answerTest.answer} element={AddHeader(<Test typeOfDisplay={displayType}/>, displayType)}/>

                        <Route path="/404" element={<HttpError code={404}/>}/>
                        <Route path="*" element={<HttpError code={404}/>}/>
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
