import colors from "../../config/colors";
import { interfaceCardLab } from "./interfaceCardLab";
import { Button, Card } from "@mui/material";
import { styled as styledMUI } from "@mui/material/styles";
import { enumListSizeDevices } from "../../config/responsive/configResponsive";
import { ButtonFromMUI } from "../MUIConfig/ButtonFromMUI";
import { motion, AnimatePresence } from "framer-motion"
import { useState } from "react";
import { Link } from "react-router-dom";


const TextP = styledMUI("div", { shouldForwardProp: (prop) => prop !== 'isShow', })<{ isShow: boolean; }>(({
    theme,
    isShow
}) => ({
    color: isShow ? colors.screens.home.card.textColorHovered : colors.screens.home.card.textColor,
    textAlign: "justify",
    paddingLeft: "1%",
    paddingRight: "1%",
    margin: "16px",
    [theme.breakpoints.down("md")]: {
        textAlign: "center",
    }
}));
const CardMain = styledMUI(Card)(({ theme }) => ({
    overflow: "visible",
    alignItems: "center",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    position: "relative",

}));
const ImageBox = styledMUI("div")(({ theme }) => ({
    width: "100%",
    display: "block",
    position: "relative",

}));
const variants = {
    visible: { opacity: 1, transition: { duration: 3 } },
    hidden: { opacity: 0 }
};


const HoverStyle = styledMUI(motion.div, { shouldForwardProp: (prop) => prop !== 'isShow', })<{ isShow: boolean; }>(({
    theme,
    isShow
}) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.screens.home.card.hoverColorInImage,
    display: isShow ? "flex" : "none",


}));
const ImageBackground = styledMUI("img")(({ theme }) => ({
    width: "100%",
    height: "auto",
    aspectRatio: "3/2",
}));
export const ButtonEnter = styledMUI(ButtonFromMUI)(({ theme }) => ({
    color: colors.buttonAction.colorTextInButton,
    textDecoration: "none",
    borderColor: colors.buttonAction.borderColorButtonInCard,
    backgroundColor: colors.buttonAction.backgroundButton,
    boxShadow: `2px 4px 4px ${colors.buttonAction.shadowButton}`,
    ':hover': {
        boxShadow: `1px 2px 4px ${colors.buttonAction.hoverShadowButton}`,
        color: colors.buttonAction.hoverColorTextInButton,
        backgroundColor: colors.buttonAction.hoverBackgroundButton,
    },
    '& span': {
        marginRight: theme.direction == "rtl" ? "8px" : "-4px",
        marginLeft: theme.direction == "rtl" ? "-4px" : "8px",
    }
}));


function CardLab(paramsCard: interfaceCardLab) {
    const [isMouseHoveredDiv, setIsMouseHoveredDiv] = useState<boolean>(false);
    return (


        <CardMain
            onMouseEnter={() => {
                setIsMouseHoveredDiv(prevState => true)
            }}
            onMouseLeave={() => {
                setIsMouseHoveredDiv(prevState => false)
            }}
        >
            <Link to={paramsCard.link}>
                <ImageBox
                >
                    <HoverStyle

                        whileHover={{
                            scale: 1.2,
                            transition: { duration: 0.3 }
                        }}
                        isShow={isMouseHoveredDiv}>

                        <ButtonEnter variant="outlined"
                            size={(paramsCard.typeOfDisplay <= enumListSizeDevices.tablet) ? "small" : (paramsCard.typeOfDisplay <= enumListSizeDevices.desktop) ? "medium" : "large"}
                        >
                            ورود به سامانه
                        </ButtonEnter>

                    </HoverStyle>
                    <ImageBackground src={paramsCard.imgBackgroundUrl} />
                </ImageBox>
            </Link>
            <TextP isShow={isMouseHoveredDiv}>{paramsCard.title}</TextP>
        </CardMain>
    )
}

export default CardLab;