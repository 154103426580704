import colors from "../../../config/colors";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { Button, Card, TextField, Theme, useTheme } from "@mui/material";
import { TextWithLink } from "../../../components/text_with_link_text/TextWithLink";
import EditText from "../../../components/editText/EditText";
import { styled as styledMUI } from "@mui/material/styles";
import { NavigateOptions, useNavigate } from "react-router-dom";
import links from "../../../config/links";
import { useState } from "react";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { useDispatch } from "react-redux";
import { SetTokenAndRefreshToken } from "../../../store/profileStateSlice";
import { InterfaceAnswerTest } from "./interfaceAnswerTest";
import { InterfaceResponseServer } from "../test/interfaceTest";

const DivAllTextContainer = styledMUI(Card)(({ theme }) => ({
    display: "flex",
    color: "black",
    // marginLeft:"5%",
    paddingLeft: "5%",
    // marginRight:"5%",
    paddingRight: "5%",
    paddingTop: "calc(48px + 2vh)",
    background: "rgba(254,254,254,0.6)",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    zIndex: 100,

    position: "absolute",
    top: "50%",
    maxWidth: "80%",
    minWidth: "30%",
    transform: "translate(0,-60%)",

}));
const ImageBackGround = styledMUI("img")(({ theme }) => ({
    objectFit: "cover",
    position: "absolute",
    top: 0,
    right: theme.direction == "rtl" ? 0 : undefined,
    left: theme.direction == "rtl" ? undefined : 0,
    zIndex: 0,
    maxHeight: "100vh",
    minWidth: "50%",

    alignItems: "center",
    width: "100%",
    height: "100%",

}));

const BackgroundGradientLeft = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: 0,
    height: "100%",
    left: 0,
    zIndex: 2,
    maxHeight: "100vh",
    [theme.breakpoints.down('md')]: {
        background: "linear-gradient(270deg, rgba(0, 0, 0, 0) 2.14%, rgba(0, 0, 0, 0.25) 44.41%, rgba(0, 0, 0, 0.65) 73.44%, #000000 99.92%)",
        width: "70%",
    },
    [theme.breakpoints.up('md')]: {
        background: theme.direction == "rtl" ?
            "#000000" :
            "linear-gradient(90deg, rgba(0, 0, 0, 0) 2.14%, rgba(0, 0, 0, 0.25) 44.41%, rgba(0, 0, 0, 0.65) 73.44%, #000000 99.92%)",
        width: "51%",
    }


}));
const BackgroundGradientRight = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: 0,
    height: "100%",
    right: 0,
    background: "#FF0000",
    zIndex: 2,
    maxHeight: "100vh",
    [theme.breakpoints.down('md')]: {
        background: "linear-gradient(90deg, rgba(0, 0, 0, 0) 2.14%, rgba(0, 0, 0, 0.25) 44.41%, rgba(0, 0, 0, 0.65) 73.44%, #000000 99.92%)",
        width: "70%",
    },
    [theme.breakpoints.up('md')]: {
        background: theme.direction == "ltr" ?
            "#000000" :
            "linear-gradient(270deg, rgba(0, 0, 0, 0) 2.14%, rgba(0, 0, 0, 0.25) 44.41%, rgba(0, 0, 0, 0.65) 73.44%, #000000 99.92%)",
        width: "51%",
    }
}));


function AnswerTest(params: InterfaceAnswerTest) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const [getEditTextPhone, setEditTextPhone] = useState<string>("");
    const [getEditTextReceptionCode, setEditTextReceptionCode] = useState<string>("");


    const onClickSubmitHandler = async () => {
        dispatch(isVisibilityProgressBar(true));
        const strQuery=`?ReceptionCode=${getEditTextReceptionCode}&Phone=${getEditTextPhone}`

        await RequestHandler(dispatch, HandlerLangUrl(urlsList.login.url + strQuery, mTheme),
            urlsList.login.method, urlsList.login.isTokenNecessary, undefined)
            .then((response: any) => {
                console.log("response:)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                if(response.Success == false){
                    dispatch(ShowAlert({ text: response.Message, typeAlert: enumTypeOfAlert.error, lastTimeSecondsUpdate: (new Date()).getSeconds() }));
                }
                else{
                    dispatch(ShowAlert({ text: `سلام ${response.Data.FullName}`, typeAlert: enumTypeOfAlert.success, lastTimeSecondsUpdate: (new Date()).getSeconds() }));
                    navigate(links.answerTest.answer,{ state: response as InterfaceResponseServer });
                }
                
                
                
            })
            .catch((e: Error) => {
                console.log("response:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: confComps.screen.answerTest.login.notification.internetFailed, typeAlert: enumTypeOfAlert.error, lastTimeSecondsUpdate: (new Date()).getSeconds() }));
            });


    }
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "start",
            justifyContent: "center",
            minHeight: "100vh",
            backgroundImage: "green",
            backgroundRepeat: "no-repeat",
            position: "relative"
        }}>
            <ImageBackGround
                src="/assets/img/background.jpg"
                srcSet="/assets/img/background.jpg"
                alt="not loaded"
                loading="lazy"
            />
            {/* <BackgroundGradientLeft />
            <BackgroundGradientRight /> */}


            <DivAllTextContainer>

                <EditText title={confComps.screen.answerTest.login.editTextReceptionCode.text}
                    isNecessary={confComps.screen.answerTest.login.editTextReceptionCode.isNecessary}
                    hintText={confComps.screen.answerTest.login.editTextReceptionCode.hintText} 
                    isMultiLine={confComps.screen.answerTest.login.editTextReceptionCode.isMultiLine}
                    isSmallTitle={false} onChangeValue={setEditTextReceptionCode}
                    isColorDark={true}
                    isSmallHintShow={false}

                    styledTextField={{ width: "100%" }}

                    isDataMustLtr={true} />

                <EditText title={confComps.screen.answerTest.login.editTextPhone.text}
                    isNecessary={confComps.screen.answerTest.login.editTextPhone.isNecessary}
                    hintText={confComps.screen.answerTest.login.editTextPhone.hintText} 
                    isMultiLine={confComps.screen.answerTest.login.editTextPhone.isMultiLine}
                    isSmallTitle={false} onChangeValue={setEditTextPhone}
                    styledTextField={{ width: "100%" }}
                    isSmallHintShow={false}
                    isColorDark={true}
                    isDataMustLtr={true}
                     />


                <Button variant="contained" fullWidth={true}
                    onClick={onClickSubmitHandler}
                    style={{
                        background: colors.screens.login.btnAccept.background,
                        paddingTop: "3.5%",
                        paddingBottom: "3.5%",
                        marginTop: "5%",
                        borderRadius: "5px"
                    }}>
                    {confComps.screen.answerTest.login.buttonEnter.text}
                </Button>
                <br />
                {/* <TextWithLink list={confComps.screen.loginAndRegister.login.textRedirect} /> */}

            </DivAllTextContainer>


        </div>
    )
}

export default AnswerTest;