const links={
    answerTest:{
        login:"/answer/login",
        answer:"/answer/test",
    },
    loginAndRegister:{
        login:"/panel/login",
        register:"/panel/register",
    },
    panel:{
        dashboard:"/panel/main",
        Account:"/panel/account"
    },
    home:"/home",


}
export default links;