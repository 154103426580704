import {styled as styledMUI} from "@mui/material/styles";
import {InterfaceHome} from "./interfaceHome";
import {Grid} from "@mui/material";
// import {fa} from "../../config/locales/fa/fa_text";
import CardLab from "../../components/card_lab/cardLab";
import { ConfigComps } from "../../config/ConfigCOMP";

const HeaderCustom = styledMUI("header")(({theme}) => ({
    background: "linear-gradient(45deg,#60a741 37%,#304770 0)",
    overflow:"hidden",
    padding:"1.2em",
    color:"white",

}));
const BoxInSide = styledMUI("div")(({theme}) => ({
    marginRight:"auto",
    marginLeft:"auto",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    gap:"1vw",
    alignContent:"center",

}));

const HeaderWithTitle = (paramsPage:InterfaceHome) => {
    var confComps = ConfigComps();
    return (
        <Grid container
                     justifyContent="space-evenly" alignItems={"stretch"}
                     columnSpacing={{xs: 1, sm: 1, md: 2, lg: 3, xl: 5}}
                     paddingRight={{xs: 6, sm: 3, md: 7, lg: 8, xl: 9}}
                     paddingLeft={{xs: 6, sm: 3, md: 7, lg: 8, xl: 9}}
                     paddingTop={{xs: 2, sm: 2, md: 3, lg: 3, xl: 3}}
                     paddingBottom={{xs: 6, sm: 6, md: 8, lg: 10, xl: 12}}
                     rowSpacing={{xs: 4, sm: 5, md: 5, lg: 5, xl: 5}}
                     columns={{xs: 10, sm: 3, md: 4, lg: 4, xl: 4}}
                     style={{}}>
            {confComps.screen.home.labList.map((value, index, array) => {
                return (
                    <Grid item xs={9} sm={1} md={1} lg={1} xl={1} key={index}
                          style={{position: "relative", display: "flex"}}>
                        <CardLab title={value.name}  imgBackgroundUrl={value.backgroundUrl} link={value.links} typeOfDisplay={paramsPage.typeOfDisplay}/>
                    </Grid>
                )
            })}
        </Grid>
    )
}
export default HeaderWithTitle;