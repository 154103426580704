const colors = {
    editText: {
        h6TitleColor: "#22395e",
        backgroundUnselected: "#fefefe",
        darkTitleColor: "rgb(35,53,50)",
        lightTitleColor: "#fefefe",
    },
    appBar: {
        backgroundColor: "#D4D4D4",
        buttonBackgroundColor: "#54C0EE",
        textBtn: "#FFFFFF",
    },
    titleOfMainPages: {
        selectedLinks: "#ECA712",
        unSelectedLinks: "#FFFFFF"
    },
    hamburgerMenu: {
        backgroundColor: "#F1F1F1",
        textUnselected: "#14213D",
        textSelected: "#FFFFFF",
        ButtonSelected: "#09477B",
        iconColor: "#09477B",
        iconExit: "#D90429",
    },
    footer: {
        textSelected: "#54C0EE",
        textUnselected: "#ffffff",
    },
    buttonAction: {
        shadowButton: "rgba(0,0,0,0.25)",
        hoverShadowButton: "rgba(13,50,78,0.71)",
        backgroundButton: "rgb(33,133,208)",
        hoverBackgroundButton: "rgba(92,131,161,0.48)",
        colorTextInButton: "#ffffff",
        hoverColorTextInButton: "rgba(16,73,116)",
        borderColorButtonInCard: "rgba(16,73,116)",
    },
    screens:{
        home:{
            card:{
                textColor:"#010101",
                textColorHovered:"#1e70bf",
                hoverColorInImage:"hsla(0,0%,100%,.6)",
            }
        },
        login:{
            btnAccept:{
                background:"rgb(35,53,50)",
            }
        }
    }
}
export default colors;