import colors from "../../../config/colors";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { Button, Card, Grid, TextField, Theme, useTheme } from "@mui/material";
import { TextWithLink } from "../../../components/text_with_link_text/TextWithLink";
import EditText from "../../../components/editText/EditText";
import { styled as styledMUI } from "@mui/material/styles";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import links from "../../../config/links";
import { useState } from "react";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { useDispatch } from "react-redux";
import { SetTokenAndRefreshToken } from "../../../store/profileStateSlice";
import { InterfaceResponseServer, InterfaceTest } from "./interfaceTest";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";


const DivAllTextContainer = styledMUI(Card)(({ theme }) => ({
    display: "flex",
    color: "black",
    paddingTop: "calc(48px + 2vh)",
    background: "rgba(254,254,254,0.6)",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "start",
    zIndex: 100,
    maxHeight:"100%",

    // position: "absolute",
    // top: "2%",
    minWidth: "90vw",
    padding: "24px",

}));
const CardTestContainer = styledMUI(Card)(({ theme }) => ({
    display: "flex",
    color: "black",
    paddingTop: "calc(48px + 2vh)",
    background: "rgba(254,254,254,0.6)",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "start",
    width: "80%",
    padding: "24px",

}));
const ImageBackGround = styledMUI("img")(({ theme }) => ({
    objectFit: "cover",
    position: "absolute",
    top: 0,
    right: theme.direction == "rtl" ? 0 : undefined,
    left: theme.direction == "rtl" ? undefined : 0,
    zIndex: 0,
    maxHeight: "100vh",
    minWidth: "50%",

    alignItems: "center",
    width: "100%",
    height: "100%",

}));

const PTextTitle1 = styledMUI("h6")(({ theme }) => ({
    color: colors.editText.darkTitleColor,
    whiteSpace: "nowrap",


}));
const PTextValue1 = styledMUI("p")(({ theme }) => ({
    color: colors.editText.darkTitleColor,
    // whiteSpace: "nowrap",


}));
const DivGroup1 = styledMUI("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    gap: "calc(1vw + 8px)",


}));




function Test(params: InterfaceTest) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let response = useLocation().state;
    window.history.replaceState({}, document.title)
    const mTheme = useTheme();
    var confComps = ConfigComps();
    try {
        let responseServer = response as InterfaceResponseServer;
        console.log("responseServer");
        console.log("responseServer");
        console.log(responseServer);
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "start",
                justifyContent: "center",
                maxHeight: "calc(100vh - 4em)",
                overflow:"scroll",
                backgroundImage: "green",
                backgroundRepeat: "no-repeat",
                position: "relative",
                background:`url(/assets/img/background.jpg)`,
            }}>
                {/* <ImageBackGround
                    src="/assets/img/background.jpg"
                    srcSet="/assets/img/background.jpg"
                    alt="not loaded"
                    loading="lazy"
                /> */}
                {/* <BackgroundGradientLeft />
            <BackgroundGradientRight /> */}


                <DivAllTextContainer>
                    <GridFromMUI container
                        justifyContent="start" alignItems="stretch"
                        paddingRight={{ xs: 3, sm: 8, md: 1, lg: 2, xl: 2 }}
                        paddingLeft={{ xs: 3, sm: 8, md: 1, lg: 2, xl: 2 }}
                        paddingTop={{ xs: 1, sm: 1, md: 1, lg: 0, xl: 1 }}
                        paddingBottom={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
                        columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 5, xl: 6 }}
                        rowSpacing={{ xs: 4, sm: 5, md: 5, lg: 5, xl: 5 }}
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        marginTop={{ xs: 0 }}
                        marginBottom={{ xs: 0 }}
                        marginLeft={{ xs: 0 }}
                    >
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>نام :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.FullName}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>جنسیت :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.Sex}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>جنسیت :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.Sex}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>تولد :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.BirthDate}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>کد ملی :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.NationalCode}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>موبایل :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.Phone}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>کد پذیرش :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.ReceptionCode}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>پذیرش :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.ReceptionDate}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>نتیجه :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.ResultDate}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>باقی مانده :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.RemainingAmount}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>دکتر :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.DoctorName}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>کد دکتر :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.DoctorCode}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>کد :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.UserId}</PTextValue1>
                            </DivGroup1>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                            <DivGroup1>
                                <PTextTitle1>نام کاربری :</PTextTitle1>
                                <PTextValue1>{responseServer.Data.UserName}</PTextValue1>
                            </DivGroup1>
                        </Grid>




                    </GridFromMUI>
                    <br />
                    <div>

                        {responseServer.Data.Result.map((result, index, array) => {
                            return (

                                <GridFromMUI container
                                    justifyContent="center" alignItems="center"
                                    paddingRight={{ xs: 3, sm: 8, md: 1, lg: 2, xl: 2 }}
                                    paddingLeft={{ xs: 3, sm: 8, md: 1, lg: 2, xl: 2 }}
                                    paddingTop={{ xs: 1, sm: 1, md: 1, lg: 0, xl: 1 }}
                                    paddingBottom={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
                                    columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 5, xl: 6 }}
                                    rowSpacing={{ xs: 4, sm: 5, md: 5, lg: 5, xl: 5 }}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                    marginTop={{ xs: 0 }}
                                    marginBottom={{ xs: 0 }}
                                    marginLeft={{ xs: 0 }}
                                >
                                    {result.Tests.map((test, index, array) => {
                                        return (
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{
                                                display:"flex",
                                                flexDirection:"row",
                                                alignItems:"center",
                                                justifyContent:"center",
                                            }}>
                                                <CardTestContainer>

                                                    <GridFromMUI container
                                                        justifyContent="start" alignItems="stretch"
                                                        paddingRight={{ xs: 3, sm: 8, md: 1, lg: 2, xl: 2 }}
                                                        
                                                        paddingBottom={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
                                                        columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 5, xl: 6 }}
                                                        rowSpacing={{ xs: 4, sm: 5, md: 5, lg: 5, xl: 5 }}
                                                        columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                                        marginTop={{ xs: 0 }}
                                                        marginBottom={{ xs: 0 }}
                                                    >
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <DivGroup1>
                                                                <PTextTitle1>بخش :</PTextTitle1>
                                                                <PTextValue1>{result.Section}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <DivGroup1>
                                                                <PTextTitle1> اسم :</PTextTitle1>
                                                                <PTextValue1>{test.Name}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <DivGroup1>
                                                                <PTextTitle1>  بازه :</PTextTitle1>
                                                                <PTextValue1>{test.NormalRange}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                            <DivGroup1>
                                                                <PTextTitle1> نام :</PTextTitle1>
                                                                <PTextValue1>{test.Order}</PTextValue1>
                                                            </DivGroup1>

                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                            <DivGroup1>
                                                                <PTextTitle1> نتیجه :</PTextTitle1>
                                                                <PTextValue1>{test.Result}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                            <DivGroup1>
                                                                <PTextTitle1>  واحد :</PTextTitle1>
                                                                <PTextValue1>{test.Unit}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                            <DivGroup1>
                                                                <PTextTitle1> روش :</PTextTitle1>
                                                                <PTextValue1>{test.Method}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                            <DivGroup1>
                                                                <PTextTitle1> وضعیت :</PTextTitle1>
                                                                <PTextValue1>{test.Status}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                            <DivGroup1>
                                                                <PTextTitle1> نظر: </PTextTitle1>
                                                                <PTextValue1>{test.Comment}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                            <DivGroup1>
                                                                <PTextTitle1> کد تست:</PTextTitle1>
                                                                <PTextValue1>{test.TestCode}</PTextValue1>
                                                            </DivGroup1>
                                                        </Grid>





                                                    </GridFromMUI>
                                                </CardTestContainer>

                                            </Grid>
                                        )
                                    })}

                                </GridFromMUI>
                            )
                        })
                        }
                    </div>

                </DivAllTextContainer>
            </div>
        )
    }
    catch (e: any) {
        navigate(links.answerTest.login);
        return (
            <div></div>
        )
    }
}

export default Test;
