import {useState} from "react";
import {PhoneAndroidRounded, EmailRounded, MapRounded, Phone} from "@mui/icons-material";
import colors from "../../config/colors";
import {interfaceFooter} from "./interfaceFooter";
import {Link} from "react-router-dom";
import {styled as styledMUI} from "@mui/material/styles";
import {SectionFlexWrap} from "../sectionFullWidth/SectionFlexWrap";


const DivBoxColumn = styledMUI("div")(({theme}) => ({
    marginLeft: "8px",
    alignSelf:"start",
    marginRight: "8px",
    [theme.breakpoints.up('xs')]: {
        width: "90%",
        marginTop: "2rem",
    },
    [theme.breakpoints.up('sm')]: {
        width: "33%",
        height:"100%",
        marginTop: "2rem",
    },
    [theme.breakpoints.up('md')]: {
        width: "unset",
        maxWidth: "300px",
        height: "100%",
        marginTop: "2rem",
    },
    [theme.breakpoints.up('lg')]: {
        height: "100%",
        marginTop: "2rem",
    },
}));

const SectionTitle = styledMUI("h4")(({theme}) => ({
    marginBottom: "2rem",
}));
const AboutUsDesc = styledMUI("p")(({theme}) => ({
    textAlign: "justify",
    marginBottom: "2rem",
}));
const AboutUsButton = styledMUI("a")(({theme}) => ({
    color: "#54c0ee",
    cursor: "pointer",
}));
const FastAccessList = styledMUI("ul")(({theme}) => ({
    listStyle: "none",
}));
const FastAccessItem = styledMUI("li", {shouldForwardProp: (prop) => prop !== 'selected',})<{ selected: boolean; }>(({
                                                                                                                         theme,
                                                                                                                         selected
                                                                                                                     }) => ({
    marginBottom: "0rem",
    marginRight: "0rem",
    color: selected ? colors.footer.textSelected : colors.footer.textUnselected,
    position: "relative",
}));
const FastAccessItemLink = styledMUI(Link, {shouldForwardProp: (prop) => prop !== 'selected',})<{ selected: boolean; }>(({
                                                                                                                             theme,
                                                                                                                             selected
                                                                                                                         }) => ({
    marginBottom: "0.5rem",
    marginRight: "1rem",
    color: selected ? colors.footer.textSelected : colors.footer.textUnselected,
    position: "relative",
}));
const ContactList = styledMUI("ul")(({theme}) => ({
    listStyle: "none",
    display: "block",
    height: "auto",
}));

const ContactItem = styledMUI("li")(({theme}) => ({
    marginBottom: "4px",
    display: "flex",
}));
const Triangle = styledMUI("div")(({theme}) => ({
    width: 0,
    height: 0,
    border: "0.3rem solid transparent",
    borderLeft: 0,
    position: "absolute",
    right: "-1.5rem",
    top: "50%",
    transform: "translate(-30%, -50%)",
    borderRight: "0.5rem solid #54c0ee",
}));
const LogoContainer = styledMUI("div")(({theme}) => ({
    fontsize: "32px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    margin: "2px 2px 1rem 1rem",
}));

const DescContainer = styledMUI("section")(({theme}) => ({
    marginLeft: "4px",
    marginRight: "4px",
}));

const ContactTitleOne = styledMUI("p")(({theme}) => ({
    color: "#54c0ee",
    marginBottom: 0,
    marginTop: "1px",
}));


const ContactTitleTwo = styledMUI("p")(({theme}) => ({
    marginTop: "1px",
    marginBottom: 0,
}));
const Footer = (propsFooter: interfaceFooter) => {
    const isLocationContainLink = (url: string) => {
        if (window.location.href.toLowerCase().includes(url.toLowerCase())) {
            return true;
        }
        return false;
    };
    return (
        <SectionFlexWrap backgroundColorSection={"#252525"} colorSection={"white"} isFlexDirectionSelectRow={true}>
            {/*<DivBoxColumn>*/}
            {/*    <SectionTitle>*/}
            {/*        درباره ما*/}
            {/*    </SectionTitle>*/}
            {/*    <AboutUsDesc>*/}
            {/*        نیومرس در زمستان سال 1401 با همکاری چند تن از متخصصان حوزه روش های عددی و هوش مصنوعی تشکیل شد.*/}
            {/*    </AboutUsDesc>*/}
            {/*    <AboutUsButton>*/}
            {/*        مشاهده بیشتر*/}
            {/*    </AboutUsButton>*/}
            {/*</DivBoxColumn>*/}
            <DivBoxColumn>
                <SectionTitle>
                    دسترسی سریع
                </SectionTitle>
                <FastAccessList>
                    {propsFooter.listTitleOfAccessFast.map(((valueItems, index) => {
                            return (

                                <FastAccessItem selected={isLocationContainLink(valueItems.link)}>
                                    <FastAccessItemLink to={valueItems.link} style={{textDecoration: "blink"}}
                                                        selected={isLocationContainLink(valueItems.link)}>
                                        {valueItems.name}
                                        <Triangle
                                            style={{display: `${isLocationContainLink(valueItems.link) ? "block" : "none"}`}}/>
                                    </FastAccessItemLink>
                                </FastAccessItem>


                            );


                        })
                    )}

                </FastAccessList>
            </DivBoxColumn>
            <DivBoxColumn>
                <SectionTitle>
                    اطلاعات تماس
                </SectionTitle>
                <ContactList>
                    <ContactItem>
                        <LogoContainer>
                            <PhoneAndroidRounded/>
                        </LogoContainer>
                        <DescContainer>
                            <ContactTitleOne>شماره تماس</ContactTitleOne>
                            <ContactTitleTwo>09191111111</ContactTitleTwo>
                        </DescContainer>
                    </ContactItem>
                    <ContactItem>
                        <LogoContainer>
                            <EmailRounded/>
                        </LogoContainer>
                        <DescContainer>
                            <ContactTitleOne>ایمیل</ContactTitleOne>
                            <ContactTitleTwo>example@gmail.com</ContactTitleTwo>
                        </DescContainer>
                    </ContactItem>
                    <ContactItem>
                        <LogoContainer>
                            <MapRounded/>
                        </LogoContainer>
                        <DescContainer>
                            <ContactTitleOne>آدرس</ContactTitleOne>
                            <ContactTitleTwo>تهران، نیاوران</ContactTitleTwo>
                        </DescContainer>
                    </ContactItem>
                </ContactList>
            </DivBoxColumn>
        </SectionFlexWrap>
    )
}
export default Footer;