import links from "./links";
import {
    AutoGraphRounded,
    BarChartOutlined, CallRounded, DateRangeOutlined, DesignServicesRounded,
    DiamondRounded,
    DiamondSharp,
    Diversity3Outlined, EmojiEventsOutlined,
    ExtensionRounded,
    FlagRounded,
    FlagSharp,
    FolderCopyOutlined,
    FolderSharp, GroupRounded,
    GroupSharp, HomeRounded,
    RollerShadesRounded,
    RollerShadesSharp,
    SettingsSharp,
    SettingsVoiceOutlined,
    SpeedOutlined,
    SupervisorAccountRounded,
    SvgIconComponent,
    TextIncreaseRounded,
    TimerOutlined,
    TimerSharp,
    TuneOutlined, Warning, WebRounded
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";

export const ConfigComps = () => {
    const { t, i18n } = useTranslation();

    return (
        {
            pageNotFound: t('pageNotFound'),

            footerMainPage: {
                informationContact: {
                    title: t('footerMainPage.informationContact.title'),
                    phoneNumber: t('footerMainPage.informationContact.phoneNumber'),
                    valuePhoneNumber: t('footerMainPage.informationContact.valuePhoneNumber'),
                    email: t('footerMainPage.informationContact.email'),
                    address: t('footerMainPage.informationContact.address'),
                    valueAddress: t('footerMainPage.informationContact.valueAddress'),
                },
                fastAccess: {
                    title: t('footerMainPage.fastAccess.title'),
                },
                aboutUs: {
                    title: t('footerMainPage.aboutUs.title'),
                    discription: t('footerMainPage.aboutUs.discription'),
                    moreDetails: t('footerMainPage.aboutUs.moreDetails'),
                },
            },
            screen: {
                answerTest: {
                    login: {
                        notification: {
                            internetFailed: t('screen.answerTest.login.notification.internetFailed'),
                        },
                        buttonEnter:{
                            text: t('screen.answerTest.login.buttonEnter.text'),
                        },
                        editTextPhone:{
                            text:t('screen.answerTest.login.editTextPhone.text'),
                            isNecessary:true,
                            hintText:t('screen.answerTest.login.editTextPhone.hintText'),
                            isMultiLine:false,
                        },
                        editTextReceptionCode:{
                            text:t('screen.answerTest.login.editTextReceptionCode.text'),
                            isNecessary:true,
                            hintText:t('screen.answerTest.login.editTextReceptionCode.hintText'),
                            isMultiLine:false,
                        }
                    },
                    test: {

                    }
                },

                panel: {
                    appBar: {
                        title: t('panel.appBar.title'),
                        hamburgerMenu: {
                            processes: t('panel.appBar.hamburgerMenu.processes'),
                            transactions: t('panel.appBar.hamburgerMenu.transactions'),
                            tickets: t('panel.appBar.hamburgerMenu.tickets'),
                            account: t('panel.appBar.hamburgerMenu.account'),
                            exit: t('panel.appBar.hamburgerMenu.exit'),
                        },
                    },


                },
                home: {
                    labList: [
                        {
                            name: "کیوان",
                            links: links.answerTest.login,
                            backgroundUrl: "./assets/img/lab1.png",
                        },
                        {
                            name: "نگین آزاده",
                            links: links.answerTest.login,
                            backgroundUrl: "./assets/img/lab1.png",
                        },
                        {
                            name: "کیوان",
                            links: links.answerTest.login,
                            backgroundUrl: "./assets/img/lab1.png",
                        },
                        {
                            name: "نگین آزاده",
                            links: links.answerTest.login,
                            backgroundUrl: "./assets/img/lab1.png",
                        },
                    ],


                },
            }




        }
    )
}
