import {styled as styledMUI} from "@mui/material/styles";

export const SectionFlexWrap = styledMUI("div", {shouldForwardProp: (prop) => prop !== 'backgroundColorSection' && prop !== 'colorSection' && prop !== 'isFlexDirectionSelectRow'})<{ backgroundColorSection: string; colorSection?: string; isFlexDirectionSelectRow: boolean; }>(({
                                                                                                                                                                                                                                                                                        theme,
                                                                                                                                                                                                                                                                                        backgroundColorSection,
                                                                                                                                                                                                                                                                                        colorSection,
                                                                                                                                                                                                                                                                                        isFlexDirectionSelectRow,
                                                                                                                                                                                                                                                                                    }) => ({
    display: "flex",
    flexDirection: isFlexDirectionSelectRow ? "row" : "column",
    flexWrap: "wrap",
    backgroundColor: backgroundColorSection,
    color: colorSection,
    justifyContent: "space-between",
    alignCenter: "center",
    alignItems:"center",
    padding: "1vw 8px",
    gap: "3vh",

    [theme.breakpoints.up('xs')]: {
        justifyContent: "space-between",
        paddingLeft: "10%",
        paddingRight: "10%",
        gap: 10,

    },
    [theme.breakpoints.up('sm')]: {
        justifyContent: "space-between",
        paddingLeft: "7%",
        paddingRight: "7%",
        gap: 15,

    },
    [theme.breakpoints.up('md')]: {
        justifyContent: "space-between",
        paddingLeft: "7%",
        paddingRight: "7%",
        gap: 15,
    },
    [theme.breakpoints.up('lg')]: {
        justifyContent: "space-between",
        paddingLeft: "7%",
        paddingRight: "7%",
        gap: 15,
    },
    [theme.breakpoints.up('xl')]: {
        justifyContent: "space-between",
        paddingLeft: "7%",
        paddingRight: "7%",
        gap: 15
    }


}));