import {InterfaceHeaderWithTitle} from "./interfaceHeaderWithTitle";
import {styled as styledMUI} from "@mui/material/styles";
import {Container} from "@mui/material";

const HeaderCustom = styledMUI("header")(({theme}) => ({
    background: "linear-gradient(45deg,#304770 40%,#60a741 0)",
    overflow: "hidden",
    color: "white",
    padding:"1em",

}));
const BoxInSide = styledMUI("div")(({theme}) => ({
    marginRight: "auto",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems:"center",

}));

const HeaderWithTitle = (paramsPage: InterfaceHeaderWithTitle) => {
    return (
        <HeaderCustom>
            <Container>
                <BoxInSide>
                    <h4>{paramsPage.name}</h4>
                    <img src={"/assets/img/logo-white.png"} style={{maxWidth:"25%"}}/>
                </BoxInSide>
            </Container>
        </HeaderCustom>
    )
}
export default HeaderWithTitle;