import { Theme, useTheme } from "@mui/material/styles";;

// Define SupportedMethods type as filter type of methods axios use case.
export enum SupportedMethods {
    get = "get",
    post = "post",
    put = "put",
    delete = "delete",
    patch = "patch"
};
const baseUrl = "https://azmoonproxy.niktech.org/server"//"https://numerse.niktech.org"
// Put your new endpoint like here.
export const HandlerLangUrl = (url: string, mtheme: any) => {
    return (`${baseUrl}${url}`)
}

export const urlsList = {

        login: { url: `/ReceptionResult`, method: SupportedMethods.get, isTokenNecessary: false, isFileInclude: false },
        register: {
            generateOTP: { },
            validateOTP: { },
            setPassword: { },
        },
        refreshToken: { url: `/ReceptionResult`, method: SupportedMethods.get, isTokenNecessary: false, isFileInclude: false },

    panel: {
        home:{}
    }
};